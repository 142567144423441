/**
 * sro-licence2.ru
 * Top search
 * @author      delphinpro <delphinpro@gmail.com>
 */

$(function () {
    let $searchButton = $('.search-button');
    let $searchBar    = $('.top-search');
    let $backdrop     = $('<div/>', {'class':'backdrop backdrop'});
    let $body         = $(document.body);
    let stateOpen     = $body.hasClass('show-search');

    function toggleSearchBox() {
        if (!stateOpen) {
            $backdrop.appendTo($body).fadeIn(300);
        } else {
            $backdrop.fadeOut(100);
        }
        stateOpen = !stateOpen;
        $body.toggleClass('show-search');
    }

    $backdrop.on('click', toggleSearchBox);

    $searchButton.on('click', function () {
        toggleSearchBox();
    });
});
