/**
 * Ajax отправка форм
 *
 * @author  delphinpro <delphinpro@gmail.com>
 */

$(function () {
    $('[data-toggle="ajax"]').on('submit', function (e) {
        e.preventDefault();

        let $form        = $(this);
        let $formLoading = $('.ajax-form__loading', $form);
        let $button      = $('[type="submit"]', $form);

        let stop = false;
        $('input[type="text"], textarea', $form).each(function(i, el){
            if (el.name !== 'email' && el.value && /@/.test(el.value)) {
                stop = true;
            }
        });
        if ($form.closest('.green-form')) stop = false; // temporary fix
        if (stop) return;

        let data     = $form.serializeArray();
        let action   = $form.data('action') || $form.attr('action');
        let method   = $form.attr('method');
        let response = $form.data('response') || 'json';

        let fd = new FormData();

        for (let i in data) {
            if(!data.hasOwnProperty(i)) continue;
            let param = data[i];
            fd.append(param.name, param.value);
        }

        $button.attr('disabled', true);
        $formLoading.addClass('show');
        $form.trigger('beforeSend');

        let resetForm = function () {
            $formLoading.removeClass('show');
            $button.attr('disabled', false);
            $form.trigger('reset');
        };

        let $file = $('input[type="file"]', $form);
        if ($file.length) {
            $.each($file[0].files, function (i, file) {
                fd.append($file.attr('name'), file);
            });
        }

        fd.append('ajax', 1);

        $.ajax({
            url        : action,
            type       : method,
            data       : fd,
            dataType   : response,
            cache      : false,
            contentType: false,
            processData: false,
            complete   : function () {
                resetForm();
            },
        }).then(function (data) {
            console.log('Response:', data);
            $form.trigger('success', [data]);
        }).fail(function () {
            $form.trigger('error');
        });
    });
});
