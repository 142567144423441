/**
 * Menu
 *
 * @author      delphinpro <delphinpro@gmail.com>
 */

$(function () {
    const delayDropdown = 150;

    function createTag(tagName, classes) {
        return $(`<${tagName}/>`, {
            'class': classes,
        });
    }

    function createLink(classes = '', attrs) {
        return $('<a/>', {
            'class': attrs.active ? classes + ' active' : classes,
            href   : attrs.href || '#',
            html   : attrs.text || '',
        });
    }

    function isNotArray(arr) {
        return !arr || typeof arr !== 'object' || typeof arr.length === 'undefined'
    }

    function render(list, $container) {
        if (!list.length) return;

        let $wMenuPanel = createTag('ul', 'wide-menu__panel');
        let nextList, $currentItem;

        for (let i in list) {
            if (!list.hasOwnProperty(i)) continue;

            let item = list[i];

            if (isNotArray(item.children)) { // Cast to array
                item.children = [];
            }

            let $item = createTag('li', 'wide-menu__item');
            let $link = createLink('wide-menu__link', item);

            $link.data('children', item.children);
            $link.data('siblings', list);
            $link.data('current', item);

            $link.toggleClass('has-children', item.children.length > 0);

            if (!nextList || item.active) {
                nextList = item.children;
                if (!!item.children.length) {
                    $currentItem = $link;
                }
            }

            $link.appendTo($item);
            $item.appendTo($wMenuPanel);
        }

        $currentItem && $currentItem.addClass('active');

        $wMenuPanel.appendTo($container);
        render(nextList, $container);
    }

    function positionPointer($item) {
        let $pointer = $item.data('pointer');
        if (!$pointer) return;

        let pointerWidth = $pointer.outerWidth();
        let itemPosition = $item.position();
        let itemWidth    = $item.outerWidth();

        $pointer.css({
            left: (itemPosition.left + itemWidth / 2 - pointerWidth / 2),
        });
    }

    let data = window.menu || false;

    if (!data) return;

    let $menu     = $('#menu');
    let $menuList = $('.menu__list', $menu);

    $menuList.empty();
    for (let i in data) {
        if (!data.hasOwnProperty(i)) continue;

        let item = data[i];

        if (isNotArray(item.children)) { // Cast to array
            item.children = [];
        }

        let $item = createTag('li', 'menu__item');
        let $link = createLink('menu__link', item);

        $link.data('children', item.children);
        $link.appendTo($item);

        if (item.children.length) {
            let $menuDropdown = createTag('div', 'menu__dropdown');
            let $menuPointer  = createTag('div', 'menu__pointer');
            let $menuCloser   = createTag('div', 'menu__closer');
            let $wMenu        = createTag('div', 'wide-menu');

            $wMenu.data('items', item.children);
            $menuPointer.appendTo($menuDropdown);
            $menuCloser.appendTo($menuDropdown);
            $wMenu.appendTo($menuDropdown);
            $menuDropdown.appendTo($item);
            render(item.children, $wMenu);

            $item.data('pointer', $menuPointer);
        }

        $item.on('mouseenter', () => {
            $item.data(
                'debounce',
                setTimeout(function () {
                    $item.siblings().removeClass('is-visible');
                    $item.addClass('is-visible');
                    positionPointer($item);
                }, delayDropdown)
            )
        });

        $item.on('mouseleave', () => clearTimeout($item.data('debounce')));

        $item.appendTo($menuList);
    }

    $(document).on('click', '.menu__closer', e => {
        e.stopPropagation();
        e.preventDefault();
        $('.menu__item', $menu).removeClass('is-visible');
    });

    $(document.body).on('click.widemenu', e => {
        let $target     = $(e.target);
        let parentClass = 'menu__dropdown';

        if (!($target.hasClass(parentClass)) && !$target.closest(`.${parentClass}`).length) {
            $('.menu__item', $menu).removeClass('is-visible');
        }
    });

    $menu.on('click', '.wide-menu__link', e => {
        let $link     = $(e.target);
        let $children = $link.data('children');
        let $siblings = $link.data('siblings');
        let current   = $link.data('current');

        if ($children.length > 0) {
            e.preventDefault();
            e.stopPropagation();
        }

        $siblings.forEach(item => item.active = false);
        $children.forEach(item => item.active = false);
        current.active = true;

        let $wMenu = $link.closest('.wide-menu');
        let items  = $wMenu.data('items');

        $wMenu.empty();
        render(items, $wMenu);
    });
});
