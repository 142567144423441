/**
 * sro-licence2.ru
 * Input type=file
 * @author      delphinpro <delphinpro@gmail.com>
 */

$(function () {
    let file_api = !!( window.File && window.FileReader && window.FileList && window.Blob );

    $('.form-control-file').each((i, el) => {
        // console.log(el);
        let input = $('input[type="file"]', el);
        let label = $('.form-control-file__text', el);

        input.change(() => {
            let file_name;

            if (file_api && input[0].files[0]) {
                file_name = input[0].files[0].name;
            }

            if (!file_name) {
                label.text('');
                return;
            }

            label.text(file_name);
        }).change();
    });
});
