/**
 * sro-licence2.ru
 * @author      delphinpro <delphinpro@gmail.com>
 */

$(function () {
    function height($rh) {
        let h = $rh.height();
        $rh.toggleClass('request__header_bg_bottom', h > 400);
    }

    $('.request__header').each((i, el) => {
        let $requestHeader = $(el);

        $(window).on('resize', () => {
            height($requestHeader);
        });

        height($requestHeader);
    });
});
