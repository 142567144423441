/**
 * sro-licence2.ru
 * Form validation
 * @author      delphinpro <delphinpro@gmail.com>
 */

$(function () {
    $(window).trigger('validatorsLoaded'); // Иначе не загружается локализация

    $.formUtils.addValidator({
        name             : 'tel',
        validatorFunction: function (value, $el, config, language, $form) {
            return /^([+_()\s\d-]{10,})/.test(value);
        },
        errorMessage     : 'Некорректный<br>номер телефона',
        errorMessageKey  : 'badTel'
    });

    $('form').attr('novalidate', true).each(function () {
        let enableScrollOnError = !!$(this).data('enableScrollOnError');
        let firstInvalid        = true;
        $.validate({
            form                   : $(this),
            // modules                : 'file',
            lang                   : 'ru',
            language               : {
                requiredField: 'Поле не<br>заполнено',
                badEmail: 'Некорректный<br>E-mail',
            },
            scrollToTopOnError     : false,
            borderColorOnError     : '',
            onValidate             : function ($form) {
                firstInvalid = true;
            },
            onElementValidate      : function (valid, $el, $form, errorMess) {
                if (!valid && firstInvalid) {
                    // $('html, body').animate({scrollTop: $el.offset().top - 100}, 300);
                    firstInvalid = false;
                }
            }
        });
    });
});
