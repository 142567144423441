/**
 * sro-licence2.ru
 * Yandex map
 * @author      delphinpro <delphinpro@gmail.com>
 */

$(function () {
    let selector           = '#index-map';
    let $block             = $(selector);
    let $tplBalloon        = $('#map-balloon');
    let $tplBalloonContent = $('#map-balloon-content');
    let $tplBalloonContact = $('#map-balloon-contact');
    if (!$block.length
        || !$tplBalloon.length
        || !$tplBalloonContent.length
        || !$tplBalloonContact.length) return;

    let centerDefault = [55.89711159436251, 37.39985283213179];
    let customIcon    = {
        iconLayout     : 'default#image',
        iconImageHref  : '/design/images/icons/point-red.png',
        iconImageSize  : [36, 56],
        iconImageOffset: [-18, -56]
    };

    let tplBalloon        = $tplBalloon.html();
    let tplBalloonContent = $tplBalloonContent.html();
    let tplBalloonContact = $tplBalloonContact.html();

    let points = GEO_POINTS || {};

    let $api = $('<script/>', {
        src : '//api-maps.yandex.ru/2.1/?lang=ru_RU',
        type: 'text/javascript'
    });

    let i = setInterval(function () {
        if (!!window.ymaps) {
            clearInterval(i);

            console.info('Yandex map API has been loaded.');

            ymaps.ready(function () {
                let map = new ymaps.Map(selector.replace('#', ''), {
                    center: centerDefault,
                    zoom  : 5
                });

                map.controls.remove('geolocationControl');
                map.controls.remove('searchControl');
                map.controls.remove('trafficControl');
                map.controls.remove('typeSelector');
                map.controls.remove('fullscreenControl');
                map.controls.remove('rulerControl');
                map.behaviors.disable(['scrollZoom']);

                for (let place of points) {
                    let MyBalloonLayout = ymaps.templateLayoutFactory.createClass(tplBalloon, {
                        /**
                         * Строит экземпляр макета на основе шаблона и добавляет его в родительский HTML-элемент.
                         * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#build
                         * @function
                         * @name build
                         */
                        build: function () {
                            this.constructor.superclass.build.call(this);

                            this._$element = $('.map-balloon', this.getParentElement());

                            this.applyElementOffset();

                            this._$element.find('.close')
                                .on('click', $.proxy(this.onCloseClick, this));
                        },

                        /**
                         * Удаляет содержимое макета из DOM.
                         * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/layout.templateBased.Base.xml#clear
                         * @function
                         * @name clear
                         */
                        clear: function () {
                            this._$element.find('.close')
                                .off('click');

                            this.constructor.superclass.clear.call(this);
                        },

                        /**
                         * Метод будет вызван системой шаблонов АПИ при изменении размеров вложенного макета.
                         * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
                         * @function
                         * @name onSublayoutSizeChange
                         */
                        onSublayoutSizeChange: function () {
                            MyBalloonLayout.superclass.onSublayoutSizeChange.apply(this, arguments);

                            if (!this._isElement(this._$element)) {
                                return;
                            }

                            this.applyElementOffset();

                            this.events.fire('shapechange');
                        },

                        /**
                         * Сдвигаем балун, чтобы "хвостик" указывал на точку привязки.
                         * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
                         * @function
                         * @name applyElementOffset
                         */
                        applyElementOffset: function () {
                            console.log('applyElementOffset');
                            this._$element.css({
                                left: 20,
                                // left: -(this._$element[0].offsetWidth / 2),
                                // top : -(this._$element[0].offsetHeight + this._$element.find('.arrow')[0].offsetHeight)
                                top : -(this._$element[0].offsetHeight / 2)
                            });
                        },

                        /**
                         * Закрывает балун при клике на крестик, кидая событие "userclose" на макете.
                         * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/IBalloonLayout.xml#event-userclose
                         * @function
                         * @name onCloseClick
                         */
                        onCloseClick: function (e) {
                            e.preventDefault();

                            this.events.fire('userclose');
                        },

                        /**
                         * Используется для автопозиционирования (balloonAutoPan).
                         * @see https://api.yandex.ru/maps/doc/jsapi/2.1/ref/reference/ILayout.xml#getClientBounds
                         * @function
                         * @name getClientBounds
                         * @returns {Number[][]} Координаты левого верхнего и правого нижнего углов шаблона относительно точки привязки.
                         */
                        getShape: function () {
                            if (!this._isElement(this._$element)) {
                                return MyBalloonLayout.superclass.getShape.call(this);
                            }

                            let position = this._$element.position();

                            return new ymaps.shape.Rectangle(new ymaps.geometry.pixel.Rectangle([
                                [position.left, position.top], [
                                    position.left + this._$element[0].offsetWidth,
                                    position.top + this._$element[0].offsetHeight + this._$element.find('.arrow')[0].offsetHeight
                                ]
                            ]));
                        },

                        /**
                         * Проверяем наличие элемента (в ИЕ и Опере его еще может не быть).
                         * @function
                         * @private
                         * @name _isElement
                         * @param {jQuery} [element] Элемент.
                         * @returns {Boolean} Флаг наличия.
                         */
                        _isElement: function (element) {
                            return element && element[0] && element.find('.arrow')[0];
                        }
                    });

                    // Создание вложенного макета содержимого балуна.
                    let MyBalloonContentLayout = ymaps.templateLayoutFactory.createClass(tplBalloonContent);

                    let balloonContent = '';

                    if (place.content.address) {
                        balloonContent += tplBalloonContact
                            .replace('<% icon %>', 'point')
                            .replace('<% content %>', place.content.address);
                    }

                    if (place.content.schedule) {
                        balloonContent += tplBalloonContact
                            .replace('<% icon %>', 'clock')
                            .replace('<% content %>', place.content.schedule);
                    }

                    if (place.content.phone) {
                        balloonContent += tplBalloonContact
                            .replace('<% icon %>', 'phone')
                            .replace('<% content %>', place.content.phone);
                    }

                    if (place.content.email) {
                        let emails = '';
                        for (let email of place.content.email) {
                            emails += `<p><a href="mailto:${email[0]}">${email[0]}</a> <em>${email[1]}</em></p>`;
                        }
                        balloonContent += tplBalloonContact
                            .replace('<% icon %>', 'mail')
                            .replace('<% content %>', emails);
                    }

                    // Создание метки с пользовательским макетом балуна.
                    let placemark = new ymaps.Placemark(place.geo, {
                        balloonHeader : place.content.title,
                        balloonContent: balloonContent
                    }, $.extend(customIcon, {
                        balloonShadow         : false,
                        balloonLayout         : MyBalloonLayout,
                        balloonContentLayout  : MyBalloonContentLayout,
                        balloonPanelMaxMapArea: 0,
                        // Не скрываем иконку при открытом балуне.
                        hideIconOnBalloonOpen : false,
                        // И дополнительно смещаем балун, для открытия над иконкой.
                        balloonOffset: [3, 0],
                    }));

                    map.geoObjects.add(placemark);

                    if (place.center) {
                        centerDefault = place.geo;
                    }
                }

                map.setCenter(centerDefault);

            });
        }
    }, 100);

    $api.appendTo(document.body);
});
