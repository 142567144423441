/**
 * sro-licence2.ru
 * select-dropdown
 * @author      delphinpro <delphinpro@gmail.com>
 */

$(function () {
    $('.select-dropdown').each(function () {
        let $el = $(this);
        let $selectedEmail = $el.find('.select-dropdown__selected');
        let $selectedText = $el.find('.select-dropdown__selected-text');

        $el.find('.dropdown-menu a').on('click', e => {
            e.preventDefault();
            let text = e.target.innerHTML;
            let email = e.target.getAttribute('href').replace('mailto:', '');
            $selectedText.text(text);
            $selectedEmail.text(email);
            $(e.target).parent().addClass('active').siblings().removeClass('active');
        });
    });
});
