/**
 * Accordion
 */
$(document).ready(function () {
    let stateOpenClass = 'accordion_state_expanded';

    $('.accordion').each(function () {
        let $accordion    = $(this);
        let $toggler      = $('.accordion__title', $accordion);
        let $panel        = $('.accordion__panel', $accordion);
        let $content      = $('.accordion__content', $accordion);
        let $arrowButton  = $('.accordion__button', $accordion);
        let contentHeight = $content.outerHeight();

        function accordionExpand() {
            $panel.css({'max-height': contentHeight + 'px'});
            $accordion.addClass(stateOpenClass);
        }

        function accordionCollapse() {
            $panel.css({'max-height': ''});
            $accordion.removeClass(stateOpenClass);
        }

        function accordionToggle(e) {
            e.preventDefault();
            if (!$accordion.hasClass(stateOpenClass)) {
                accordionExpand();
            } else {
                accordionCollapse();
            }
        }

        $toggler.on('click', accordionToggle);
        $arrowButton.on('click', accordionToggle);
    });
});
