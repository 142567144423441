/**
 * sro-licence2.ru
 * Обработка форм
 * @author      delphinpro <delphinpro@gmail.com>
 */

// todo переписать эту простыню потом
$(function () {
    let $modal       = $('#modal-callback');
    let $form        = $modal.find('form');
    let $formContent = $('.ajax-form__content', $modal);
    let $formSuccess = $('.ajax-form__success', $modal);
    let $formError   = $('.ajax-form__error', $modal);

    $modal.on('show.bs.modal', () => {
        $form.find('input').not('[type=hidden]').val('');
        $formContent.fadeIn();
        $formSuccess.hide();
        $formSuccess.hide();
    });

    $form.on('success', () => {
        $form.find('input').not('[type=hidden]').val('');
        $formContent.hide();
        $formSuccess.fadeIn();
    });

    $form.on('error', () => {
        $formContent.hide();
        $formError.fadeIn();
    });
});

$(function () {
    let $modal       = $('#modal-ask');
    let $form        = $modal.find('form');
    let $formContent = $('.ajax-form__content', $modal);
    let $formSuccess = $('.ajax-form__success', $modal);
    let $formError   = $('.ajax-form__error', $modal);

    $modal.on('show.bs.modal', () => {
        $formContent.fadeIn();
        $formSuccess.hide();
        $formSuccess.hide();
    });

    $form.on('success', () => {
        $form.find('input, textarea').not('[type=hidden]').val('');
        $formContent.hide();
        $formSuccess.fadeIn();
    });

    $form.on('error', () => {
        $formContent.hide();
        $formError.fadeIn();
    });
});

$(function () {
    let $modal       = $('#modal-order');
    let $form        = $modal.find('form');
    let $formContent = $('.ajax-form__content', $modal);
    let $formSuccess = $('.ajax-form__success', $modal);
    let $formError   = $('.ajax-form__error', $modal);

    $modal.on('show.bs.modal', () => {
        $formContent.fadeIn();
        $formSuccess.hide();
        $formSuccess.hide();
    });

    $form.on('success', () => {
        $form.find('input, textarea').not('[type=hidden]').val('');
        $formContent.hide();
        $formSuccess.fadeIn();
    });

    $form.on('error', () => {
        $formContent.hide();
        $formError.fadeIn();
    });
});

$(function () {
    $('.request-form, .green-form').each((i, el) => {
        let $form        = $(el).find('form');
        let $formContent = $('.ajax-form__content', $form);
        let $formSuccess = $('.ajax-form__success', $form);
        let $formError   = $('.ajax-form__error', $form);

        $form.on('success', () => {
            console.info('The form was sent successfully.');
            $form.find('input, textarea').not('[type=hidden]').val('');
            $formContent.addClass('is-hidden');
            $formSuccess.removeClass('is-hidden');
        });

        $form.on('error', () => {
            console.info('Form not sent!');
            $formContent.addClass('is-hidden');
            $formError.removeClass('is-hidden');
        });

        $form.on('click', '[data-toggle=close]', e => {
            e.preventDefault();
            $formContent.removeClass('is-hidden');
            $formError.addClass('is-hidden');
            $formSuccess.addClass('is-hidden');
        });
    });
});

// $(function () {
//     $('.green-form').each(e => {
//         let $form        = $(e.target).find('form');
//         let $formContent = $('.ajax-form__content', $(this));
//         let $formSuccess = $('.ajax-form__success', $(this));
//         let $formError   = $('.ajax-form__error', $(this));
//
//         $form.on('success', () => {
//             $form.find('input, textarea').not('[type=hidden]').val('');
//             $formContent.addClass('is-hidden');
//             $formSuccess.removeClass('is-hidden');
//         });
//
//         $form.on('error', () => {
//             $formContent.addClass('is-hidden');
//             $formError.removeClass('is-hidden');
//         });
//
//         $(this).on('click', '[data-toggle=close]', e => {
//             e.preventDefault();
//             $formContent.removeClass('is-hidden');
//             $formError.addClass('is-hidden');
//             $formSuccess.addClass('is-hidden');
//         });
//     });
// });

$(function () {
    $('.sale-table [href="#modal-order"]').on('click', e => {
        e.preventDefault();
        let text   = $(e.target).closest('tr').find('.sale-table__service').text();
        let $modal = $('#modal-order');
        console.log(text);
        $modal.one('shown.bs.modal', () => {
            $modal.find('textarea[name=message]').val(text);
        });
        $modal.modal('show');
    })
});
